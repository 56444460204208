<template>
  <Modal :id="id" :modalTitle="modalTitle" :class="classes">
    <form autocomplete="off" id="packAddForm" @submit.prevent="submit()">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="name">Name *</label>
            <input type="text" class="form-control" id="name" v-model.trim.lazy="v$.name.$model">
            <div v-if="v$.name.$dirty">
              <small class="form-text text-danger" v-if="v$.name.required.$invalid">Required Field</small>
            </div>
          </div>
        </div>
        <div class="col-12">
          <label for="investedCapital">Invested capital *</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <div class="input-group-text">$</div>
            </div>
            <input type="text" class="form-control" id="investedCapital" v-model.trim.lazy="v$.investedCapital.$model">
          </div>
          <div v-if="v$.investedCapital.$dirty">
            <small class="form-text text-danger" v-if="v$.investedCapital.required.$invalid">Required Field</small>
            <small class="form-text text-danger" v-if="v$.investedCapital.numeric.$invalid">Value must be a number</small>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <button class="btn btn-primary float-right" v-bind:class="{ disabled: v$.$invalid }" :disabled="v$.$invalid">
            <span v-if="!loading">ADD</span>
            <img v-if="loading" src="@/assets/loader.svg" height="20" alt="">
          </button>
        </div>
      </div>
    </form>
  </Modal>
</template>

<script>

import Modal from '../../../Shared/Modal.vue';
import axios from 'axios';
import useVuelidate from '@vuelidate/core';
import { required, numeric } from '@vuelidate/validators'

export default {
  name: 'ClientDetail',
  props: [
    'id',
    'modalTitle',
    'class',
  ],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      classes: this.class,
      name: '',
      investedCapital: 0.0,
      loading: false,
    };
  },
  validations() {
    return {
      name: {
        required,
      },
      investedCapital: {
        required,
        numeric,
      }
    }
  },
  components: {
    Modal
  },
  methods: {
    submit() {
      this.loading=true;
      const bodyToSend = {
        name: this.name,
        invested_capital: this.investedCapital,
        cryptocurrencies: []
      };

      axios.post("/cryptos/packs/add",bodyToSend)
      .then(()=> {
        this.loading = false;
        this.closeModal();
        this.clearForm();
        this.$emit('packAdded');
      })
    },
    clearForm(){
      document.querySelector('#AddPack form').reset();
    },
    closeModal(){
      document.querySelector('#AddPack .close').click();
    }
  }
};
</script>

<style>
  legend{
    width:auto;
  }
</style>